<template>
  <div
    class="form-wrapper mt-1"
    style="box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(SUB_RENTAL_PERIOD) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="periodFields['period_rental_start_dispatch'].type"
          v-model="eventInformation['period_rental_start_dispatch']"
          :config="{allowInvalidPreload: true, defaultDate: eventInformation['period_rental_start_dispatch'], minDate: 'today'}"
          v-bind="getProps('period_rental_start_dispatch', periodFields)"
          @input="handleChangeDates('period_rental_dispatch_start_time')"
        />
        <component
          :is="periodFields['period_rental_end_receive'].type"
          v-model="eventInformation['period_rental_end_receive']"
          :config="{allowInvalidPreload: true, defaultDate: eventInformation['period_rental_end_receive'], minDate: 'today'}"
          v-bind="getProps('period_rental_end_receive', periodFields)"
          @input="handleChangeDates('period_rental_receive_end_time')"
        />
        <component
          :is="periodFields['event_reference'].type"
          ref="event_reference_ref"
          v-model="eventInformation['event_reference']"
          v-bind="getProps('event_reference', periodFields)"
        />
      </b-col>
      <b-col md="6">
        <component
          :is="periodFields['period_rental_dispatch_start_time'].type"
          id="period_rental_dispatch_start_time"
          ref="period_rental_dispatch_start_time_ref"
          v-model="eventInformation['period_rental_dispatch_start_time']"
          v-bind="getProps('period_rental_dispatch_start_time', periodFields)"
          the-size="sm"
          height="39px"
          :is-only-button="true"
        >
          <template #input-extra-el>
            <b-form-input
              id="period_rental_dispatch_start_time"
              v-model="eventInformation['period_rental_dispatch_start_time']"
              type="time"
            />
          </template>
        </component>
        <component
          :is="periodFields['period_rental_receive_end_time'].type"
          id="period_rental_receive_end_time"
          ref="period_rental_receive_end_time_ref"
          v-model="eventInformation['period_rental_receive_end_time']"
          v-bind="getProps('period_rental_receive_end_time', periodFields)"
          :is-only-button="true"
          the-size="sm"
          height="39px"
        >
          <template #input-extra-el>
            <b-form-input
              id="period_rental_receive_end_time"
              v-model="eventInformation['period_rental_receive_end_time']"
              type="time"
            />
          </template>
        </component>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import { getProps, checkIsAllRequiredFieldsFilled } from '@core/utils/utils'
import { format } from 'date-fns'
import config from '../config'

export default {
  name: 'SubRentalPeriod',
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
  },
  methods: {
    handleChangeDates(type) {
      if (this.eventInformation[type]) return
      this.$store.commit(`${this.MODULE_NAME}/SET_EVENT_INFORMATION_FORM`, {
        ...this.eventInformation,
        [type]: format(new Date(), 'HH:mm'),
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      periodFields,
      SUB_RENTAL_PERIOD,
      periodRequiredFields,
    } = config()

    return {
      MODULE_NAME,
      periodFields,
      SUB_RENTAL_PERIOD,
      periodRequiredFields,
      checkIsAllRequiredFieldsFilled,
      getProps,
    }
  },
}
</script>
<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
