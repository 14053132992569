<template>
  <div>
    <span class="font-weight-bolder">{{ $t("Attachments") }}</span>
    <div class="l-file-upload mb-1">
      <div class="d-flex justify-content-end mb-1">
        <feather-icon
          style="min-width: 37px; min-height: 37px"
          icon="LAddButtonIcon"
          size="24"
          class="cursor-pointer feather-add-icon"
          @click="$refs.refLFileUploadInput.click()"
        />
      </div>
      <!-- ===================================== -->
      <div class="upload-file">
        <div class="">
          <input
            v-show="false"
            ref="refLFileUploadInput"
            :accept="formattedAcceptProps"
            type="file"
            @input="fileInputChanged"
          >
        </div>
      </div>
      <div>
        <div
          class="d-flex justify-content-center"
        >
          <b-table-simple
            class="upload-attachment-table"
          >
            <b-thead>
              <b-tr class="">
                <b-th
                  v-for="({ label, thStyle }, idx) in uploadAttachmentTableCol"
                  :key="idx"
                  :style="thStyle"
                >
                  <b>{{ label }}</b>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody />
          </b-table-simple>

        </div>
        <div v-if="!isLoading">
          <div
            v-if="!customerInformationAttachments.length"
            class="text-center border"
            style="padding: 8px 20px 8px 10px;  border-radius: 5px;"
          >
            {{ $t("None Found") }}
          </div>
          <b-table-simple
            v-else
            class="upload-attachment-table border"
            style="border-radius: 5px"
          >
            <b-tbody>
              <b-tr
                v-for="(file, index) in customerInformationAttachments"
                :key="index"
              >
                <b-th class="border-0">
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    style="width: 100px"
                    :title="file.id ? file.name : file.file.name"
                  >
                    {{ file.id ? file.name : file.file.name }}
                  </span>
                </b-th>
                <b-th class="border-0">
                  <span
                    v-b-tooltip.noninteractive.hover
                    :title="file.id ? convertTZ(file.created_at) : '---'"
                    class="file-name"
                    style="width: 80px"
                  >
                    {{ file.id ? convertTZ(file.created_at) : "---" }}
                  </span>
                </b-th>
                <b-th class="border-0">
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    style="width: 100px"
                    :title="file.id ? file.mime_type : file.file.type"
                  >
                    {{ file.id ? file.mime_type : file.file.type }}
                  </span>
                </b-th>
                <b-th class="border-0">
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    style="width: 80px"

                    :title="file.id ? getCategoryName(file.attachment_category_id): ''"
                  >
                    {{ file.id ? getCategoryName(file.attachment_category_id): getCategoryName(file.attachment_category_id) }}
                  </span>
                </b-th>
                <b-th class="border-0">
                  <div class="d-flex justify-content-end">
                    <div class="upload-file__files-list-item-del">
                      <feather-icon
                        icon="LTrashIcon"
                        size="28"
                        style="padding: 5px"
                        class="cursor-pointer mr-1 border-dotted feather-trash-icon"
                        @click="deleteAttachment(file.id, index)"
                      />
                    </div>
                    <div class="d-flex">
                      <span>
                        <span>
                          <feather-icon
                            v-if="file.id"
                            icon="LDownloadIcon"
                            size="28"
                            style="padding: 5px"
                            class="cursor-pointer border-dotted feather-download-icon mr-1"
                            @click="uploadAttachment(file, index)"
                          />
                        </span>
                      </span>
                      <span>
                        <a @click="showAttachment(file)">
                          <feather-icon
                            icon="LEyeIcon"
                            size="28"
                            style="padding: 5px"
                            class="cursor-pointer border-dotted feather-eye-icon"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <!-- ===================================== -->
    </div>
    <b-modal
      id="attachment-create-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      :ok-disabled="isOkUploadBtnActive"
      :cancel-disabled="isCancelUploadBtnActive"
      cancel-variant="outline-primary"
      @hidden="clearData"
      @ok="uploadFile"
    >
      <b-overlay :show="isUploading">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                {{ $t("What category is this file?") }}
              </h3>
            </b-col>
            <b-col>
              <l-select
                v-model="sendData.attachment_category_id"
                name="attachment_category_id"
                :field="{
                  options: { transition: '' },
                  store: 'attachmentCategoryListContact',
                  fParams: {
                    type: 'subrental_orders'
                  }
                }"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal
      id="delete-confirmation-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Yes"
      ok-variant="success"
      cancel-variant="outline-primary"
      :ok-disabled="isOkTrashBtnActive"
      :cancel-disabled="isCancelTrashBtnActive"
      @ok="deleteSelectedItem"
      @cancel="hideModalConfirmation"
      @hide="hideModalConfirmation"
    >
      <b-overlay :show="isMovingToTrash">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                {{ $t('Are you sure you want to delete this document? This operation can not be undone') }}
              </h3>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BThead,
  BTr,
  BTh,
  BModal,
  BOverlay,
  BSpinner,
  BTableSimple,
  BTbody,
  VBTooltip,
} from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import axios from 'axios'
import config from './config'

export default {
  name: 'Attachment',
  components: {
    BRow,
    BCol,
    BThead,
    BTr,
    BTh,
    BModal,
    BOverlay,
    BTbody,
    BSpinner,
    BTableSimple,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => ['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp'],
    },
    moduleName: {
      type: String,
      required: true,
    },
    isSubRent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isUploading: false,
      isLoading: false,
      deletingItemId: null,
      isMovingToTrash: false,
      deletingItemIndex: null,
      chosenFiles: null,
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        file: null,
      },
    }
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray
    },
    customerID() {
      return this.$store.state[this.moduleName].customerInformationForm.customer_id
    },
    attachmentCategoryListContact() {
      return this.$store.state.listModule.attachmentCategoryListContact ?? []
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    customerInformationAttachments() {
      return this.$store.state[this.moduleName].customerInformationForm.attachments ?? []
    },
    isOkTrashBtnActive() {
      return this.isUploading
    },
    isCancelTrashBtnActive() {
      return this.isUploading
    },
  },
  methods: {
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showAttachment(file) {
      if (file.id) {
        window.open(file.url, '_blank')
      } else {
        window.open(
          (window.URL || window.webkitURL).createObjectURL(file.file),
          '_blank',
        )
      }
    },
    fileInputChanged(e) {
      this.chosenFiles = e.target.files
      this.showModalToChoseCategory()
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refLFileUploadInput.value = null
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    getCategoryName(id) {
      return (
          this.attachmentCategoryListContact.find(item => +item.id === +id)
            ?.name ?? 'no category'
      )
    },
    convertToFormData(mappedDataObject) {
      const formData = new FormData()
      Object.entries(mappedDataObject).forEach(([itemKey, itemValue]) => {
        formData.append(itemKey, itemValue)
      })
      return formData
    },
    uploadFile() {
      if (this.checkTheFileType(this.mapDataObject().file?.type)) {
        return
      }
      if (this.mapDataObject().file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.isUploading = false
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.customerInformationAttachments.push(this.mapDataObject())
    },
    hideModalConfirmation() {
      this.$bvModal.hide('delete-confirmation-modal')
    },
    showConfirmationModal() {
      this.$bvModal.show('delete-confirmation-modal')
    },
    deleteAttachment(id, index) {
      this.deletingItemId = id
      this.deletingItemIndex = index
      this.showConfirmationModal()
    },
    deleteSelectedItem() {
      const id = this.deletingItemId
      const index = this.deletingItemIndex
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch('listModule/deleteAttachment', { id })
          .then(() => {
            this.$store.commit(`${this.moduleName}/SET_CUSTOMER_INFORMATION_FORM`, {
              ...this.$store.state[this.moduleName].customerInformationForm,
              attachments: this.customerInformationAttachments.filter(
                attachment => attachment.id !== id,
              ),
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.moduleName,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.customerInformationAttachments.splice(index, 1)
      }
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.hideModalToChoseCategory()
        this.isUploading = false
      }
      return isErrorAvailable
    },
    uploadAttachment(file) {
      if (file) {
        this.show = true
        const fileUrl = file.url
        axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob',
        })
          .then(res => {
            const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileBlob
            const fileName = fileUrl.split('/').pop()
            fileLink.setAttribute('download', fileName)
            fileLink.setAttribute('id', 'test-download')
            // fileLink.setAttribute('target', '_blank')
            document.body.appendChild(fileLink)
            fileLink.click()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Uploading This file', {
                  module: this.moduleName,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.show = false
          })
      }
    },
  },
  setup() {
    const {
      uploadAttachmentTableCol,
    } = config()

    return {
      uploadAttachmentTableCol,
    }
  },
}
</script>
<style lang="scss" scoped>
.is-editable-file-upload {
  opacity: 0.5;
  pointer-events: none;
}

.upload-attachment-table {
  margin-bottom: 0;

  th {
    padding: 5px !important;
    font-weight: normal;
  }

  & .file-name,
  & .file-data {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
