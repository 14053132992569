import { formatNumberToCurrencyView } from '@core/utils/utils'
import { format } from 'date-fns'

export default {
  STOCK_ITEMS_TITLE: 'Stock Items',
  tableColumnsStockItems: [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '12%', padding: '5px 12px' },
      tBodyThStyle: { width: '21%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%', padding: '5px 12px' },
      tBodyThStyle: { width: '10%' },
    },
    {
      key: 'mpn',
      label: 'MPN',
      thStyle: { width: '10%', padding: '5px 12px' },
      tBodyThStyle: { width: '20%' },
    },
    {
      key: 'supplier_sku',
      label: 'Supplier SKU',
      thStyle: { width: '12%', padding: '5px 12px' },
      tBodyThStyle: { width: '12%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '18%', padding: '5px 12px' },
      tBodyThStyle: { width: '1%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { minWidth: '150px', textAlign: 'center', padding: '5px 12px' },
      tBodyThStyle: { width: '14%', textAlign: 'center' },
    },
    {
      key: 'last_price',
      label: 'Last Price',
      thStyle: { width: '10%', padding: '5px 12px' },
      tBodyThStyle: { width: '13%' },
    },
    {
      key: 'last_price_date',
      label: 'Last Purchase Date',
      thStyle: { width: '15%', padding: '5px 12px' },
      tBodyThStyle: { width: '13%' },
    },
  ],
  stockAutoSuggestTableColumns: [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'available',
      label: 'Available',
    },
    {
      key: 'other_locations',
      label: 'Other Locations',
    },
    {
      key: 'rate',
      label: 'Rate',
      getValue: (value, data) => formatNumberToCurrencyView(data.rates[0]?.rate) ?? '',
    },
  ],
  formatCurrency(data) {
    const price = data?.last_price ? data?.last_price : data?.rates?.[0]?.rate
    return price ? formatNumberToCurrencyView(price) : '—'
  },
  formatDate(data) {
    const date = data?.last_purchase_date ? data?.last_purchase_date : data?.rates?.[0]?.updated_at
    return date ? format(new Date(date), 'MMMM dd, yyyy') : '—'
  },
}
