<template>
  <div
    class="form-wrapper mt-1"
    style="box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <stock-table
      class="mb-4"
      :products="orderItemsTable.products"
      :api-params="{f: {
        rules: {
          can_be_sub_rented: 1,
        }
      }}"
      @handleChangeProduct="handleChangeProduct"
    />
    <other-charges is-new-direct />
  </div>
</template>

<script>

import StockTable from '@/views/main/orders/components/stock-table/Stock-Table.vue'
import OtherCharges from './OtherCharges.vue'
import config from '../config'

export default {
  name: 'StockItems',
  components: {
    StockTable,
    OtherCharges,
  },
  computed: {
    orderItemsTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsTable
    },
  },
  methods: {
    handleChangeProduct(items) {
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, {
        ...this.orderItemsTable,
        products: items,
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
    } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
</style>
