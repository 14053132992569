<template>
  <div
    class="bg-white mb-1"
    style="
      border-radius: 5px;
      padding: 12px;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
    "
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(ORDER_INFORMATION_TITLE) }}
    </h3>
    <b-form>
      <b-row>
        <b-col
          md="12"
          class="d-flex align-items-center"
        >
          <select-account
            class="flex-grow-1"
            :customer-i-d="customerID"
            :auto-suggest-table-columns="suggestionTableColumnsSupplier"
            :module-name-orders="MODULE_NAME_ORDERS"
            :module-name="MODULE_NAME"
            :default-search-query="searchQuery"
            label="Supplier Name"
            :section-configs="{
              default:{
                onSelected: null,
              },
              name: 'default', type: 'auto-suggest-customer-section', limit: 3
            }"
            :is-search-button="true"
            @changedCustomerId="selectHandler"
            @selectHandler="setCustomerId"
            @onSearchEmpty="onSearchEmpty"
            @onInput="onInput"
          /></b-col>
      </b-row>
      <b-row>
        <b-col class="6 mt-1">
          <component
            :is="customerInformation[field].type"
            v-for="field in ['billing_contact']"
            :key="field"
            v-model="customerInformationModel[field]"
            v-bind="getProps(field, customerInformation, true, 'billing_contact', getParentValue)"
            @input="getBillingContact($event, field)"
          >
            <template slot="after-input">
              <button-dashed
                class="btnBorderRevert  ml-1"
                :disabled="!customerInformationModel.customer_id"
                :class="!customerInformationModel.customer_id ? 'opacity-50': null"
              >
                <feather-icon
                  icon="LAddButtonIcon"
                  class="cursor-pointer"
                  size="34"
                  @click="newBillingContact"
                />
              </button-dashed>
            </template>
          </component>
          <component
            :is="customerInformation[field].type"
            v-for="field in ['billing_phone', 'billing_email']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformationModel[field]"
            class="h-auto"
            :is-editable="true"
            v-bind="getProps(field, customerInformation)"
          />
          <!--          <div :class="`${!hasAddress ? 'billing_address-border-danger' : ''}`">-->
          <component
            :is="customerInformation['billing_address'].type"
            ref="billing_address_ref"
            v-model="customerInformationModel['billing_address']"
            class="h-auto"
            :is-editable="true"
            v-bind="getProps('billing_address', customerInformation)"
          />
          <!--          </div>-->
          <component
            :is="customerInformation['payment_term_id'].type"
            ref="payment_term_id_ref"
            v-model="customerInformationModel['payment_term_id']"
            v-bind="getProps('payment_term_id', customerInformation)"
          />
        </b-col>
        <b-col class="6 mt-1">
          <attachment
            :module-name="MODULE_NAME"
            is-sub-rent
          />
          <component
            :is="customerInformation['internal_account_notes'].type"
            ref="internal_account_notes_ref"
            v-model="customerInformationModel['internal_account_notes']"
            :is-editable="true"
            class="h-auto"
            v-bind="getProps('internal_account_notes', customerInformation)"
          />
          <component
            :is="customerInformation[field].type"
            v-for="field in ['internal_order_reference', 'external_order_reference']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformationModel[field]"
            class="h-auto"
            v-bind="getProps(field, customerInformation)"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BForm, BFormInput,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import SelectAccount from '@/views/main/orders/components/use-as-global/SelectAccount.vue'
import { getProps, getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import Attachment from '@/views/main/orders/components/order-attachment/Attachment.vue'
import config from '../config'

export default {
  name: 'OrderInformation',
  components: {
    ButtonDashed,
    BRow,
    BCol,
    BForm,
    BButton,
    ErrorAlert,
    BFormInput,
    Attachment,
    SelectAccount,
  },
  props: {},
  data() {
    return {
      hasAddress: true,
      searchQuery: '',
    }
  },
  computed: {
    customerInformationModel() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    customerID() {
      return this.customerInformationModel.customer_id
    },
  },
  watch: {
    customerInformationModel(val) {
      if (val) {
        this.searchQuery = val.customer?.cust_name_dba
      }
    },
  },
  methods: {
    newBillingContact() {
      const route = this.$router.resolve({ name: 'home-customers-contacts-create', params: { id: this.customerInformationModel.customer_id } })
      window.open(route.href)
    },
    getParentValue() {
      return this.customerInformationModel.customer_id
    },
    getBillingContact(obj, field) {
      if (field !== 'billing_contact' && !obj) return false
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformationModel,
        billing_phone: getValueFromGivenObjectByKey(obj, 'phone_one'),
        billing_email: getValueFromGivenObjectByKey(obj, 'email'),
      })
    },
    onInput(searchQuery) {
      this.searchQuery = searchQuery
    },
    onSearchEmpty() {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        attachments: [],
        isEmpty: true,
      })
      this.hasAddress = true
    },
    setCustomerId(item) {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformationModel,
        customer_id: item.id,
      })
    },
    selectHandler(item) {
      this.hasAddress = true

      const {
        customer_detail,
        default_address,
      } = item.item

      if (default_address === null) {
        this.hasAddress = false
      }

      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformationModel,
        billing_address: this.hasAddress ? `${getValueFromGivenObjectByKey(default_address, 'street', '—')}, ${getValueFromGivenObjectByKey(default_address, 'state.name', '—')}, ${getValueFromGivenObjectByKey(default_address, 'state.code', '—')},  ${getValueFromGivenObjectByKey(default_address, 'zip', '—')}` : 'Customer Missing Address',
        billing_address_id: this.hasAddress ? default_address.id : 0,
        internal_account_notes: getValueFromGivenObjectByKey(customer_detail, 'comments', ''),
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      customerInformation,
      ORDER_INFORMATION_TITLE,
      suggestionTableColumnsSupplier,
    } = config()

    const MODULE_NAME_CUSTOMER = 'customers'

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      MODULE_NAME_CUSTOMER,
      customerInformation,
      ORDER_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      suggestionTableColumnsSupplier,
    }
  },
}

</script>
<style lang="scss">
.billing_address-border-danger  {
  & textarea,
  & label,{
    border-color: #6f1214;
    color: #6f1214;
  }

  & label {
    font-weight: bold;
  }
}
</style>
