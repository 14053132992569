<template>
  <div id="form-header">
    <b-form>
      <validation-observer ref="subRentQuoteForm">
        <!--   Order information     -->
        <order-information />

        <!--   Dispatch And Return    -->
        <dispatch-and-return />

        <!--   Sub rental period    -->
        <sub-rental-period />

        <!--   Stock items-->
        <stock-items />

        <!--   Form bottom     -->
        <form-bottom
          :form-ref="$refs"
          :is-create-form="isCreateForm"
          :buttons-list="orderInitialStateButtons"
        />
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import OrderInformation
from './OrderInformation.vue'
import DispatchAndReturn
from './DispatchAndReturn.vue'
import SubRentalPeriod from './SubRentalPeriod.vue'
import StockItems from './StockItems.vue'
import FormBottom from './form-submit-buttons/FormBottom.vue'
import config from '../config'
import formBtnConfig from './form-submit-buttons/libs'

export default {
  name: 'CommonForm',
  components: {
    BForm,
    ValidationObserver,
    OrderInformation,
    StockItems,
    DispatchAndReturn,
    SubRentalPeriod,
    FormBottom,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  methods: {
  },
  setup() {
    const { MODULE_NAME } = config()
    const {
      orderInitialStateButtons,
    } = formBtnConfig()

    return {
      MODULE_NAME,
      orderInitialStateButtons,
    }
  },
}
</script>
