<template>
  <common-form />
</template>

<script>
import CommonForm from '@/views/main/orders/view/sub-rent/components/CommonForm.vue'
import config from '../../config'

export default {
  name: 'DirectRequestForQuote',
  components: {
    CommonForm,
  },

  created() {
  },
  setup() {
    const {
      quoteStatusBar, MODULE_NAME, CREATE_PAGE_TITLE, onClear,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE,
      onClear,
    }
  },
}
</script>
