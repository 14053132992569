export default function config() {
  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '20%',
      },
    },
    {
      label: 'Date',
      thStyle: {
        width: '20%',
      },
    },
    {
      label: 'Type',
      thStyle: {
        maxWidth: '10% !important',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '20%',
      },
    },
    {
      label: '',
      thStyle: {
        width: '10%',
      },
    },
  ]

  return {
    uploadAttachmentTableCol,
  }
}
